import dayjs from 'dayjs'
import 'dayjs/locale/id.js'
dayjs.locale('id')

export const setDate = () => {
  return dayjs().format('YYYY-MM-DD')
}

export const setTime = () => {
  return dayjs().format('HH:mm:ss')
}

export const setDateTime = () => {
  return dayjs().format('YYYY-MM-DD HH:mm:ss')
}

export const formatDate = (value: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('YYYY-MM-DD')
}

export const formatDateDDMMYYYY = (value: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('DD-MM-YYYY')
}

export const formatDateTime = (value: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
}

export const formatDateTimeDMYHM = (value: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('DD-MM-YYYY HH:mm')
}

export const formatTime = (value: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('HH:mm:ss')
}

export const formatTimeHM = (value: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('HH:mm')
}

export const formatRupiah = (value: number) => {
  if (value) {
    return new Intl.NumberFormat('de-ID').format(value)
  } else {
    return 0
  }
}

export const ParseError = (error: any) => {
  if (error.response.status == 406) {
    return error.response.data.message
  } else if (error.response.status == 401) {
    window.localStorage.clear()
    setTimeout(() => {
      location.reload()
    }, 10000)
    return error.response.data.message
  } else if (error.response.status == 500) {
    return 'Telah terjadi kesalahan pada saat proses data, silahkan kontak team IT'
  } else if (error.response.status == 422) {
    return 'Terdapat field yang belum di input, mohon untuk di input terlebih dahulu'
  } else {
    return error.message
  }
}
